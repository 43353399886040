export class ImpressaoSecundariaEntity {
  imagemLogo = null;
  aspectRatio = null;
  fantasia = null;
  cnpjCpf = null;
  enderecoLogradouro = null;
  enderecoLogradouroNumero = null;
  enderecoBairro = null;
  enderecoCidade = null;
  enderecoUF = null;
  numeroDocumento = null;
  serieDocumento = null;
  dataEmissao = null;
  clienteNome = null;
  clienteCnpjCpf = null;
  clienteSelecionado = {};
  nfSaidaItens = [];
  nfSaidaPagamentos = [];
  meioPagamentoList = [];
  totalMercadoria = null;
  acrescimo = null;
  desconto = null;
  despesas = null;
  informacoesAdicionais = null;

  constructor(data) {
    this.imagemLogo = data.imagemLogo;
    this.aspectRatio = data.aspectRatio;
    this.fantasia = data.fantasia;
    this.cnpjCpf = data.cnpjCpf;
    this.enderecoLogradouro = data.enderecoLogradouro;
    this.enderecoLogradouroNumero = data.enderecoLogradouroNumero;
    this.enderecoBairro = data.enderecoBairro;
    this.enderecoCidade = data.enderecoCidade;
    this.enderecoUF = data.enderecoUF;
    this.numeroDocumento = data.numeroDocumento;
    this.serieDocumento = data.serieDocumento;
    this.dataEmissao = data.dataEmissao;
    this.clienteNome = data.clienteNome;
    this.clienteCnpjCpf = data.clienteCnpjCpf;
    this.clienteSelecionado = data.clienteSelecionado?.nomeRazaoSocial
      ? new ClienteSelecionado(data.clienteSelecionado)
      : {};
    this.nfSaidaItens = data.nfSaidaItens?.length
      ? data.nfSaidaItens.map((item) => new NfSaidaItens(item))
      : [];
    this.nfSaidaPagamentos = data.nfSaidaPagamentos?.length
      ? data.nfSaidaPagamentos.map(
          (pagamento) => new NfSaidaPagamentos(pagamento)
        )
      : [];
    this.meioPagamentoList = data.meioPagamentoList;
    this.totalMercadoria = data.totalMercadoria;
    this.acrescimo = data.acrescimo;
    this.desconto = data.desconto;
    this.despesas = data.despesas;
    this.informacoesAdicionais = data.informacoesAdicionais;
  }
}

class NfSaidaItens {
  codigo = null;
  descricao = null;
  quantidade = null;
  unidade = null;
  valor = null;
  subtotal = null;

  constructor(item) {
    this.codigo = item.codigo;
    this.descricao = item.descricao;
    this.quantidade = item.quantidade;
    this.unidade = item.unidade;
    this.valor = item.valor;
    this.subtotal = item.subtotal;
  }
}

class NfSaidaPagamentos {
  meioPagamentoId = null;
  valorTitulo = 0;

  constructor(pagamento) {
    this.meioPagamentoId = pagamento.meioPagamentoId;
    this.valorTitulo = pagamento.valorTitulo;
  }
}

class ClienteSelecionado {
  nomeRazaoSocial = null;
  telefone = null;
  celular = null;
  enderecos = [];

  constructor(clienteSelecionado) {
    this.nomeRazaoSocial = clienteSelecionado.nomeRazaoSocial;
    this.telefone = clienteSelecionado.telefone;
    this.celular = clienteSelecionado.celular;
    this.enderecos = clienteSelecionado.enderecos?.length
      ? clienteSelecionado.enderecos.map(
          (endereco) => new EnderecoCliente(endereco)
        )
      : [];
  }
}

class EnderecoCliente {
  logradouro = null;
  numero = null;
  bairro = null;
  cidade = {
    nome: null,
    estado: {
      nome: null,
    },
  };
  cep = null;

  constructor(endereco) {
    this.logradouro = endereco.logradouro;
    this.numero = endereco.numero;
    this.bairro = endereco.bairro;
    this.cidade = {
      nome: endereco.cidade?.nome,
      estado: {
        nome: endereco.cidade?.estado?.nome,
      },
    };
    this.cep = endereco.cep;
  }
}
