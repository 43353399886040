import qrcode from "qrcode-generator";
import { impressoraModule, notification } from "../services";
import store from "../config/store";
import { addDays, addMonths } from "date-fns";

export const getListaPagamentoPadrao = (configuracao, meioPagamentoList) => {
  const idsPagamentos =
    configuracao?.meioPagamentosPadroesConfiguracoes?.map((pagamento) => ({
      id: pagamento.meioPa,
    })) || [];
  return meioPagamentoList.filter((item) =>
    idsPagamentos.some((idPagamento) => item.id === idPagamento.id)
  );
};

export const getAspectRatioLogoEmpresa = () => {
  const img = document.getElementById("logo-empresa");
  const aspectRatio = (img?.naturalWidth || 1) / (img?.naturalHeight || 1);
  return aspectRatio <= 1.5 ? 100 : 20;
};

export const getQRCodeNfce = (qrCode) => {
  const qr = qrcode(0, "L");
  qr.addData(qrCode || "");
  qr.make();
  return qr.createDataURL(4);
};

export const impressaoNfce = async (empresa, dados, impressaoFiscal) => {
  if (Object.keys(empresa)?.length && Object?.keys(dados).length) {
    const body = {
      ...empresa,
      ...dados,
      qrCodeImage: getQRCodeNfce(dados?.qrCode),
      aspectRatio: getAspectRatioLogoEmpresa(),
    };
    return impressoraModule.impressora(
      body,
      store.localInstalacao,
      impressaoFiscal
    );
  } else {
    notification.erroGenericos("Não há dados a serem impressos");
  }
};

const subtotalItem = (item) =>
  parseFloat(
    item.subtotal || item.quantidade * (item.valorUnitario || item.valor)
  );

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function verificarNotasEmitidasHoje(notas, operacaoFiscalId) {
  const notasHoje = notas.filter(
    (item) => item.operacaoFiscalId === operacaoFiscalId
  );
  return notasHoje.length > 0;
}

export function getIndexItemMaisCaro(lista) {
  return lista.reduce(
    (maxIndex, currentItem, currentIndex, array) =>
      subtotalItem(currentItem) > subtotalItem(array[maxIndex])
        ? currentIndex
        : maxIndex,
    0
  );
}

export function calculaFloor(valor, casasDecimais = 1) {
  return (
    Math.floor(valor * Math.pow(10, casasDecimais)) /
    Math.pow(10, casasDecimais)
  );
}

export function formatProdutosTotalizadores(
  name,
  nfSaidaItens,
  valueInputReal,
  totalNota
) {
  const valueInput = parseFloat(valueInputReal || 0);
  let listaAtualizada = nfSaidaItens.map((item) => {
    const valorProporcional =
      calculaFloor((subtotalItem(item) * 100) / totalNota) / 100;
    return {
      ...item,
      [name]: valueInput > 0 ? calculaFloor(valorProporcional * valueInput) : 0,
    };
  });
  ajustarDiferenca(name, valueInput, listaAtualizada);
  return listaAtualizada;
}

export function totalItensLista(listaNfSaidaProdutos) {
  if (listaNfSaidaProdutos?.length) {
    const total = listaNfSaidaProdutos.reduce(
      (prevVal, elem) => prevVal + subtotalItem(elem),
      0
    );
    return total;
  }
}

export function totalVenda(nfSaida, nfSaidaItens, calculoTotalizadores) {
  const freteNota =
    nfSaida?.freteCompoeNota === true ? parseFloat(nfSaida?.frete ?? 0) : 0;
  const subtotalProdutos = totalItensLista(nfSaidaItens);
  const subtotalDesconto = calculaSubtotalParaChave(
    nfSaidaItens,
    calculoTotalizadores,
    "desconto"
  );
  const subtotalAcrescimo = calculaSubtotalParaChave(
    nfSaidaItens,
    calculoTotalizadores,
    "acrescimo"
  );
  const subtotalDespesas = calculaSubtotalParaChave(
    nfSaidaItens,
    calculoTotalizadores,
    "despesas"
  );
  const totalVenda =
    (subtotalProdutos || 0) -
    (subtotalDesconto || 0) +
    (subtotalAcrescimo || 0) +
    (subtotalDespesas || 0) +
    (freteNota || 0);
  return parseFloat(totalVenda.toFixed(2));
}

export function calculaSubtotalParaChave(lista, tipoOperacao, chave) {
  if (lista?.length > 0) {
    const valorCalculado = lista.reduce((acumulador, objetoAtual) => {
      return acumulador + parseFloat(objetoAtual[chave] ?? 0);
    }, 0);
    return tipoOperacao !== chave ? valorCalculado : 0;
  }
}

export function totalPagamentos(nfSaidaPagamentos) {
  if (nfSaidaPagamentos?.length) {
    const total = nfSaidaPagamentos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.valorTitulo.toFixed(2)),
      0
    );
    return parseFloat(total.toFixed(2));
  }
}

export function verificaBaixaAutomatica(nfSaida, meioPagamentoList) {
  const meioPagamentoIds = [
    ...new Set(nfSaida.nfSaidaPagamentos.map((item) => item.meioPagamentoId)),
  ];
  return meioPagamentoList
    .filter((item) => item.baixaAutomatica)
    .some((item) => meioPagamentoIds.includes(item.id));
}

export function mapearEndereco(endereco) {
  return {
    ...endereco,
    cidade: endereco.cidade.nome,
    codigoIbge: endereco.cidade.codigoIbge,
    estado: endereco.cidade.estado?.sigla,
  };
}

export function mapearEnderecos(enderecos) {
  return enderecos.map((endereco) => mapearEndereco(endereco));
}

export function encontrarEstado(estados, estadoId) {
  if (estados.length) {
    return estados.find((estado) => estado.id === estadoId)?.sigla;
  }
  return "";
}

export function encontrarEnderecoPadrao(enderecos) {
  return enderecos.find((endereco) => endereco.padrao === true);
}

export function calcularPrecoProduto(
  produto,
  preco,
  tabelaPrecoCliente,
  nfSaidaCondicaoPagamento
) {
  if (
    tabelaPrecoCliente?.length > 0 &&
    nfSaidaCondicaoPagamento === tabelaPrecoCliente.condicaoPagamentoId
  ) {
    if (tabelaPrecoCliente.tipoTabela === "Por produto") {
      const tabelaPrecoProduto = tabelaPrecoCliente.tabelaPrecoProdutos.find(
        (item) => item.produtoId === produto.id
      );
      if (tabelaPrecoProduto) return tabelaPrecoProduto.precoTabela;
      return preco;
    }
    if (tabelaPrecoCliente.aplicarDescontoEm === "Percentual") {
      return preco - preco * (tabelaPrecoCliente.descontoTabela / 100);
    }
    return preco - tabelaPrecoCliente.descontoTabela;
  }
  return preco;
}

export function parcelaData(indice, data, intervaloDias) {
  const dias = indice * (intervaloDias ?? 1);
  const meses = Math.floor(dias / 30);
  const diasRestantes = dias % 30;
  const dataAtualizada = addMonths(new Date(data), meses);
  const dataFinal = addDays(dataAtualizada, diasRestantes);
  return dataFinal;
}

export function csoValueFormat(csoSimplesList, produto) {
  if (csoSimplesList) {
    const objeto = csoSimplesList?.find(
      (objeto) => objeto?.codigo === produto?.tributosIcmsCsosn
    );
    return objeto ? `${objeto.codigo} - ${objeto.descricao}` : "";
  }
}

export function compararPagamentosTotalNfSaida(nfSaida) {
  return nfSaida?.totalVenda - nfSaida?.totalPagamentos > 0;
}

export function GeraCodigoNumerico(dataEmissao) {
  const date = new Date(
    dataEmissao.getFullYear(),
    dataEmissao.getMonth(),
    dataEmissao.getDate(),
    dataEmissao.getHours(),
    dataEmissao.getMinutes(),
    dataEmissao.getSeconds(),
    dataEmissao.getMilliseconds()
  );
  const utcMilliseconds = date.getTime();
  const codigo =
    utcMilliseconds.toString().slice(4, 10) +
    utcMilliseconds.toString().slice(16);
  return codigo;
}

export function Calculo_DV11(strNumero) {
  if (!strNumero) {
    return "";
  }
  let total = 0;
  let weight = 2;
  for (let i = strNumero.length - 1; i >= 0; i--) {
    const digit = parseInt(strNumero[i], 10);
    total += digit * weight;
    weight = (weight + 1) % 9;
  }
  const remainder = total % 11;
  return remainder === 0 || remainder === 1 ? "0" : (11 - remainder).toString();
}

export function formatProdutosTotalizadoresDto(
  nfSaidaItens,
  totalNota,
  nfSaida
) {
  const { acrescimo = 0, despesas = 0, desconto = 0 } = nfSaida;
  let listaAtualizada = nfSaidaItens.map((item) => {
    const valorProporcional =
      calculaFloor((subtotalItem(item) * 100) / totalNota) / 100;
    const novosValores = {
      acrescimo: calculaFloor(valorProporcional * acrescimo),
      despesas: calculaFloor(valorProporcional * despesas),
      desconto: calculaFloor(valorProporcional * desconto),
    };
    return {
      ...item,
      ...novosValores,
    };
  });
  ajustarDiferenca("acrescimo", acrescimo, listaAtualizada);
  ajustarDiferenca("despesas", despesas, listaAtualizada);
  ajustarDiferenca("desconto", desconto, listaAtualizada);

  return listaAtualizada;
}

function ajustarDiferenca(key, valorTotal, listaAtualizada) {
  const subtotalLista = calculaSubtotalParaChave(listaAtualizada, "", key);
  const subtotal = parseFloat(subtotalLista.toFixed(2));
  if (valorTotal !== subtotal && valorTotal > 0 && subtotal > 0) {
    const indiceItemMaisCaro = getIndexItemMaisCaro(listaAtualizada, key);
    const diferenca = valorTotal - calculaFloor(subtotal);
    listaAtualizada[indiceItemMaisCaro][key] += diferenca;
  }
}

export function calculoDesconto(itens, nfSaida, porCemParaDecimal = true) {
  const total =
    totalItensLista(itens) +
    parseFloat(nfSaida?.acrescimo ?? 0) +
    parseFloat(nfSaida?.despesas ?? 0);

  let resultado;
  if (porCemParaDecimal) {
    const descontoPorcentagem = parseFloat(nfSaida?.descontoPorcentagem ?? 0);
    resultado = (descontoPorcentagem / 100) * total;
    resultado = calculaFloor(resultado, 3);
  } else {
    const desconto = parseFloat(nfSaida?.desconto ?? 0);
    resultado = (desconto / calculaFloor(total)) * 100;
    resultado = calculaFloor(resultado, 3);
  }
  return resultado || 0;
}
